import image from "./zql_logo.png";
const App = () => {
  const styles = {
    outerDiv: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "black",
      gap: 20,
    },
    image: {
      width: 100,
      height: 100,
    },
    text: {
      color: "white",
      fontSize: 20,
      marginLeft: 20,
      alignItems: "center",
      textAlign: "center",
      fontSize: 16,
    },
  };
  return (
    <div style={styles.outerDiv}>
      <img alt="" src={image} style={styles.image} />
      <div style={styles.text}>
        Hello!
        <br />
        We are Zenith Quantitative Laboratories LLC <br /> We create software.
      </div>
    </div>
  );
};

export default App;
